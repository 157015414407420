import React from 'react';

import './home.scss';
import { CuratorView } from './CuratorView';
import { KeyworderView } from './KeyworderView';
import { ReviewerView } from './ReviewerView';
import { RankerView } from './RankerView';
import { NoAccessView } from './NoAccessView';
import { useUser } from '../../../contexts';
import { ChallengeAdminView } from './ChallengeAdminView';

export const Home = () => {
  const { user } = useUser();

  return (
    <div className="Home">
      {user?.isCurator && <CuratorView user={user} />}
      {user?.isKeyworder && <KeyworderView user={user} />}
      {user?.isReviewer && <ReviewerView user={user} />}
      {user?.isRanker && <RankerView user={user} />}
      {user?.isChallengeAdmin && <ChallengeAdminView />}
      {!user?.selectedRole && <NoAccessView />}
    </div>
  );
};
