import React, { useState, useEffect, ChangeEvent } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Moment from 'moment';

import './dashboard.main.scss';
import { Chart } from '../../components/Chart';
import { LoadingProgress } from '../../components/LoadingProgress';
import {
  SummaryItemType,
  ChartOptionsProps,
} from '../../types';
import { useUser } from '../../contexts';
import { useGetStats } from '../../queries';

export const Dashboard = () => {
  const { user } = useUser();
  const { data: stats, isLoading } = useGetStats(user?.selectedRole);
  const [value, setValue] = useState(0);
  const [options, setOptions] = useState<ChartOptionsProps>({
    categories: [],
    series: [],
  });

  const parseDailyData = (items: SummaryItemType[] = []) => {
    const daysOfMonth = Array.from(Array(Moment().daysInMonth()), (_, i) =>
      Moment()
        .date(i + 1)
        .format('LL'),
    );
    const series: number[] = [];
    const categories: string[] = [];

    daysOfMonth?.forEach((dayString: string) => {
      const findItem = items.filter((item: any) => {
        return Moment(item.time_unit_beginning_date).format('LL') === dayString;
      });

      if (findItem.length) {
        series.push(findItem[0].media_count);
      } else {
        series.push(0);
      }
      categories.push(dayString);
    });

    return {
      series: series,
      categories: categories,
    };
  };

  const parseMonthlyData = (items: SummaryItemType[] = []) => {
    const monthsList = Array.apply(0, Array(12)).map((_, i) => {
      return Moment().month(i).format('MMM [/] YYYY');
    });
    const series: number[] = [];
    const categories: string[] = [];

    monthsList?.forEach((monthString: string) => {
      const findItem = items.filter((item: any) => {
        return Moment(item.time_unit_beginning_date).format('MMM [/] YYYY') === monthString;
      });

      if (findItem.length) {
        series.push(findItem[0].media_count);
      } else {
        series.push(0);
      }
      categories.push(monthString);
    });

    return {
      series: series,
      categories: categories,
    };
  };

  const parseWeeklyData = (items: SummaryItemType[] = []) => {
    const daysOfMonth = Array.from(Array(Moment().daysInMonth()), (_, i) =>
      Moment()
        .date(i + 1)
        .format('LL'),
    );
    const series: number[] = [];
    const categories: string[] = [];
    const monthMondays = daysOfMonth.filter((day: string) => Moment(day).weekday() === 1 && day);

    monthMondays?.forEach((dayString: string) => {
      const findItem = items.filter((item: any) => {
        return Moment(item.time_unit_beginning_date).format('LL') === dayString;
      });

      if (findItem.length) {
        series.push(findItem[0].media_count);
      } else {
        series.push(0);
      }
      categories.push(dayString);
    });

    return {
      series: series,
      categories: categories,
    };
  };

  const handleChange = (_: ChangeEvent<Object>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!isLoading) {
      let data: ChartOptionsProps = {
        series: [],
        categories: [],
      };

      switch (value) {
        case 0:
          data = parseDailyData(stats?.daily?.data);
          break;
        case 1:
          data = parseWeeklyData(stats?.weekly?.data);
          break;
        case 2:
          data = parseMonthlyData(stats?.monthly?.data);
          break;
        case 3:
          data = parseMonthlyData(stats?.monthly?.data);
          break;
        default:
          break;
      }

      setOptions(data);
    }
  }, [isLoading, value]);

  return (
    <div className="Dashboard">
      <div className="dashboard-chart-container">
        <div className="dashboard-chart-header">
          <div className="dashboard-chart-title">
            {!!user?.isCurator && 'Curated Media'}
            {!!user?.isKeyworder && 'Keyworded Media'}
            {!!user?.isReviewer && 'Reviewed Media'}
            {!!user?.isRanker && 'Ranked Media'}
          </div>
          <div className="dashboard-chart-tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Daily" />
              <Tab label="Weekly" />
              <Tab label="Monthly" />
            </Tabs>
          </div>
        </div>
        <div className="dashboard-chart">
          {isLoading && <LoadingProgress />}
          {!isLoading && (
            <>
              <div className="dashboard-chart-stats">
                <div>
                  Today - <strong>{stats?.today?.total}</strong>
                </div>
                <div>
                  Current Week - <strong>{stats?.thisWeek?.total}</strong>
                </div>
                <div>
                  Current Month - <strong>{stats?.thisMonth?.total}</strong>
                </div>
              </div>
              <Chart
                categories={options.categories}
                series={options.series}
              />
            </>
          )}
        </div>
      </div>
      {user?.isCurator && (
        <div className="dashboard-general-stats">
          <div className="dashboard-stats-header">
            <div className="dashboard-chart-title">General Stats</div>
          </div>
          <div className="dashboard-stats-content">
            <div>
              <div>Content</div>
              <div>
                <strong>-</strong>
              </div>
            </div>
            <div>
              <div>New</div>
              <div>
                <strong>-</strong>
              </div>
            </div>
            <div>
              <div>Ready</div>
              <div>
                <strong>-</strong>
              </div>
            </div>
            <div>
              <div>Assigned</div>
              <div>
                <strong>-</strong>
              </div>
            </div>
            <div>
              <div>Not Assigned</div>
              <div>
                <strong>-</strong>
              </div>
            </div>
            <div>
              <div>Not Submitted</div>
              <div>
                <strong>-</strong>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
