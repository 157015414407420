import { StatsPeriods, StatsProps } from '../../types';

export class StatsDTO implements StatsProps {
  public daily?: {
    total: number;
    data: [];
  };

  public weekly?: {
    total: number;
    data: [];
  };

  public monthly?: {
    total: number;
    data: [];
  };

  public today?: {
    total: number;
    data: [];
  };

  public thisWeek?: {
    total: number;
    data: [];
  };

  public thisMonth?: {
    total: number;
    data: [];
  };

  public paymentCycle?: {
    total: number;
    data: [];
  };

  updatePeriod(period: StatsPeriods, total: number, data: any) {
    Object.assign(this, { [period]: { total, data } });
  }
}
