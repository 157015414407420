import Moment from 'moment-timezone';

export const timePeriods = [{
  unit: 'daily',
  name: 'daily',
  from: Moment().tz('GMT').startOf('month').format('YYYY-MM-DD'),
  to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
}, {
  unit: 'weekly',
  name: 'weekly',
  from: Moment().tz('GMT').subtract(30, 'd').format('YYYY-MM-DD'),
  to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
}, {
  unit: 'monthly',
  name: 'monthly',
  from: Moment().tz('GMT').startOf('year').format('YYYY-MM-DD'),
  to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
}, {
  unit: 'daily',
  name: 'today',
  from: Moment().tz('GMT').startOf('day').format('YYYY-MM-DD'),
  to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
}, {
  unit: 'daily',
  name: 'thisWeek',
  from: Moment().tz('GMT').startOf('week').format('YYYY-MM-DD'),
  to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
}, {
  unit: 'daily',
  name: 'thisMonth',
  from: Moment().tz('GMT').startOf('month').format('YYYY-MM-DD'),
  to: Moment().tz('GMT').add(1, 'd').format('YYYY-MM-DD'),
}];
