import { useAuthedQuery } from '../hooks';
import logger from '../utils/logger';
import { StatsPeriods } from '../types';
import { getStats } from '../services/StatsService';
import { timePeriods } from '../constants/statsPeriods.constants';
import { StatsDTO } from '../models/Stats/Stats.dto';
import { ROLE_ADMIN, ROLE_CHALLENGE_ADMIN } from '../constants/roles.constants';

export enum DashboardKeysEnum {
  StatsUpdate = 'getStats',
}

export const useGetStats = (role?: string, options = {}) =>
  useAuthedQuery([DashboardKeysEnum.StatsUpdate, role], () => {
    const statsData = new StatsDTO();

    role && timePeriods.forEach(async (period) => {
      const response = await getStats(period.unit, period.from, period.to, role);
      let calculatedNumber = 0;
      const responseData = response?.data ?? [];

      responseData?.forEach((data: any) => {
        calculatedNumber += data.media_count;
      });

      statsData.updatePeriod(period.name as StatsPeriods, calculatedNumber, responseData);
    });

    return statsData;
  },
  {
    enabled: !!role && role !== ROLE_ADMIN && role !== ROLE_CHALLENGE_ADMIN,
    cacheTime: 0,
    onError: (error: Error) => {
      logger.error(`useGetStats hook: ${error}`);
    },
    ...options,
  });
