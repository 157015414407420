import React, { useState, useEffect } from 'react';
import { useTimeout, useKey } from 'rooks';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { BatchMediaContainer } from '../BatchMediaContainer';
import { BatchMediaForm } from '../BatchMediaForm';
import { UndoSnackbar } from '../../../components/UndoSnackbar';
import { Response, MediaItem } from '../../../types';
import './batch.container.scss';
import {

  useGetBatchMedia,
  useGetBatchMediaNoise,
  useAcceptMedia,
  useGetAssignedMedia,
  useGetGeneratedDescriptions, useGetStats,
} from '../../../queries';
import { KeyboardEvent } from '../../../services/KeyboardEventsService';
import { useUser } from '../../../contexts';

export const Batch = () => {
  const { user } = useUser();
  const { refetch: refetchStats } = useGetStats(user?.selectedRole);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [page, setPage] = useState<number | undefined>(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalMediaCount, setTotalMediaCount] = useState(0);
  const [batchMediaNoise, setBatchMediaNoise] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);
  const [selectedItems, setSelectedItems] = useState<MediaItem[]>([]);
  const [items, setItems] = useState<MediaItem[]>([]);
  const [submittedCount, setSubmittedCount] = useState<any>([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const cursorElement = items.filter(item => item.show).length ? [items.filter(item => item.show)[cursorPosition]] : [];
  const { start: redirectHome } = useTimeout(() => {
    history.push('/');
  }, 2000);
  const redirectIfNoMediaLeft = () => {
    const visibleItems = items.filter((item) => item.show);

    if (!isLoading && !visibleItems.length && !user?.isCurator) {
      redirectHome();
    }
  };
  const acceptMediaAction = useAcceptMedia({
    onSuccess: async () => {
      enqueueSnackbar(
        user?.isKeyworder ? 'Submitted.' : 'Approved.',
        {
          variant: 'success',
        },
      );

      await refetchStats();
      redirectIfNoMediaLeft();
    },
    onError: (error: any) => {
      enqueueSnackbar(
        error.data.message,
        {
          variant: 'error',
        },
      );
    },
  });

  const {
    isLoading: descriptionSuggestionLoading,
    mutate: mutateGetGeneratedDescriptions,
  } = useGetGeneratedDescriptions({
    onSuccess: (data: Response) => {
      setDescriptionSuggestions(data?.data.captionSuggestions);
    },
  });

  const { refetch: getAssignedBatch, isFetching: batchLoading, data: assignedBatch }: any =
    useGetAssignedMedia({
      role: user?.selectedRole ?? null,
      page,
      options: {
        onSuccess: (res: any) => {
          const response = res && res.data;

          if (res?.meta) {
            setTotalPage(res?.meta?.pagination?.total_pages);
            setTotalMediaCount(res?.meta?.pagination?.total);
          }

          if ((response.length && user?.isKeyworder)) {
            const mediaData: MediaItem[] = [...response].map(
              (item: MediaItem) => {
                const isValidated =
                      typeof item.validation?.data?.errors.length !== 'undefined';

                return {
                  ...item,
                  ...{
                    selected: false,
                    submitted: isValidated,
                    withError: false,
                    show: item?.is_selling_to_agencies_allowed !== 0,
                  },
                };
              },
            );
            setItems(mediaData);
          }
        },
        enabled: !!user?.selectedRole,
      },
    });

  const isBatchAvailable = (user?.isCurator || user?.isReviewer) &&
      !!assignedBatch?.data?.length &&
      (!!assignedBatch?.data[0]?.id || !!assignedBatch?.data?.id);

  const { isLoading: isGettingBatchMediaNoise } = useGetBatchMediaNoise(assignedBatch?.data?.[0]?.id || assignedBatch?.data?.id, {
    onSuccess: (data: Response) => {
      setBatchMediaNoise(data?.data);
    },
    enabled: isBatchAvailable,
  });

  const { isLoading, refetch: refetchBatchMedia } = useGetBatchMedia(assignedBatch?.data?.[0]?.id || assignedBatch?.data?.id, {
    onSuccess: (res: Response) => {
      const response = res?.data;

      if (response?.length) {
        const mediaData: MediaItem[] = [...response].map((item: MediaItem) => ({
          ...item,
          ...{
            selected: selectedItems?.map((selectedItem) => selectedItem.id)?.includes(item.id) ?? false,
            show: item?.is_selling_to_agencies_allowed !== 0,
          },
        }));
        setItems(mediaData);
      }
    },
    enabled: isBatchAvailable,
  });

  const selectAll = (status: boolean) => {
    const changedItems = items.filter(i => i.show);
    changedItems.forEach((item: MediaItem) => {
      item.selected = status;
    });

    setItems(changedItems);
    setSelectedItems(changedItems.filter((i: MediaItem) => i.selected && i.show));
  };

  const toggleSelection = (index: number, ctrlPressed: boolean) => {
    const changedItems = items.filter(i => i.show);
    setDescriptionSuggestions([]);
    if (!ctrlPressed || user?.isKeyworder) {
      changedItems.forEach((item) => {
        item.selected = false;
      });
    }
    changedItems[index].selected = !changedItems[index].selected;

    setItems(changedItems);
    setSelectedItems(changedItems.filter((i: MediaItem) => i.selected && i.show));
    mutateGetGeneratedDescriptions(items[index].id);
  };

  const onActionMedia = (mediaItems: MediaItem[], data: any) => {
    const changedItems = items.filter(i => i.show);

    mediaItems.forEach((item) => {
      const el: any = changedItems.find((o: any) => o.id === item.id);
      const index: number = changedItems.findIndex((x: any) => x.id === el.id);

      Object.entries(data.data).forEach((d: any) => {
        el[d[0]] = d[1];
      });

      changedItems[index] = el;
    });

    setItems([...changedItems.filter((i: MediaItem) => i.show)]);
    setSelectedItems(changedItems.filter((i: MediaItem) => i.selected && i.show));
  };

  const onUpdateMedia = (mediaItems: MediaItem[], data: any) => {
    const changedItems: any = items.filter(i => i.show);

    mediaItems.forEach((item) => {
      const el: any = changedItems.find((o: any) => o.id === item.id);
      const index: number = changedItems.findIndex((x: any) => x.id === el.id);

      Object.entries(data).forEach((d: any) => {
        el[d[0]] = d[1];
      });

      changedItems[index] = el;
    });

    setItems([...changedItems]);
    setSelectedItems([...changedItems.filter((i: MediaItem) => i.selected)]);
  };

  const onRefetchMedia = async () => {
    await getAssignedBatch();
    const mediaData = await refetchBatchMedia();
    const newMediaData = mediaData?.data?.data?.map((media: MediaItem) => ({
      ...media,
      show: media?.is_selling_to_agencies_allowed !== 0,
    }));

    const newCursorElement = newMediaData?.filter((item: MediaItem) => item.show).length
      ? [newMediaData.filter((item: MediaItem) => item.show)[cursorPosition]] : [];

    const changedSelectedMedia = selectedItems ? selectedItems?.map((item: MediaItem) => {
      const newMedia = newMediaData?.find((i: MediaItem) => i.id === item.id) ?? item;

      return ({
        ...newMedia,
        selected: true,
        show: item?.is_selling_to_agencies_allowed !== 0,
      });
    }) : [];

    setSelectedItems(changedSelectedMedia);

    return (changedSelectedMedia.filter(i => i.show).length || !newCursorElement[0])
      ? changedSelectedMedia.filter(i => i.show)
      : newCursorElement;
  };

  const onSubmitMedia = async (id: number) => {
    const changedItems = items.filter(i => i.show);

    changedItems.forEach((item: MediaItem) => {
      if (item.id === id) {
        item.show = false;
        item.selected = false;
      }
    });

    setItems([...changedItems]);
    setSubmittedCount([id]);
    setSelectedItems([...changedItems.filter((i: MediaItem) => i.selected && i.show)]);
    user?.isReviewer && onUndoSnackbarFinish([id]);
    await refetchStats();
  };

  const onSubmitAll = async () => {
    const changedItems = items.filter(i => i.show);
    const submittableItems: number[] = [];

    changedItems.forEach((item: MediaItem) => {
      if (item.submitted && item.show) {
        item.show = false;
        item.selected = false;
        submittableItems.push(item.id);
      }
    });

    setItems([...changedItems]);
    setSubmittedCount([...submittableItems]);
    setSelectedItems(changedItems.filter((i: MediaItem) => i.selected && i.show));
    user?.isReviewer && onUndoSnackbarFinish([...submittableItems]);
    await refetchStats();
  };

  const onUndoSnackbarFinish = (count?: number[]) => {
    acceptMediaAction.mutate({
      mediaData: {
        media_ids: count || submittedCount,
      },
      role: user?.selectedRole,
    });
    setSubmittedCount([]);
  };

  const onUndoSnackbar = () => {
    const changedItems: any = items;

    submittedCount.forEach((id: any) => {
      changedItems.find((o: any) => o.id === id).show = true;
    });
    setItems([...changedItems]);
    setSubmittedCount([]);
  };

  const onPageChange = (e?: any, pageNumber?: number) => {
    setPage(pageNumber);
    setSelectedItems([]);
    setItems([]);
  };

  useEffect(() => {
    getAssignedBatch();
  }, [page]);

  useKey(17, () => {
    KeyboardEvent.ctrlPressed = true;
  }, {
    eventTypes: ['keydown'],
  });
  useKey(17, () => {
    KeyboardEvent.ctrlPressed = false;
  }, {
    eventTypes: ['keyup'],
  });

  return (
    <div className="BatchContainer">
      <div className="batch-media-container">
        <BatchMediaContainer
          page={page}
          totalPage={totalPage}
          totalMediaCount={totalMediaCount}
          batchMediaNoise={batchMediaNoise}
          onPageChange={onPageChange}
          isLoading={batchLoading || isLoading || isGettingBatchMediaNoise}
          batch={(assignedBatch && assignedBatch.data[0]) || {}}
          items={items?.filter(i => i.show)}
          cursorPosition={cursorPosition}
          setCursor={setCursorPosition}
          toggleSelection={toggleSelection}
          selectedItems={selectedItems}
          onSubmitAll={onSubmitAll}
          onUpdateMedia={onUpdateMedia}
          selectAll={selectAll}
          user={user}
        />
      </div>
      <div className="batch-media-form">
        <BatchMediaForm
          batch={(assignedBatch && assignedBatch.data[0]) || {}}
          media={(selectedItems.filter(i => i.show).length || !cursorElement[0]) ? selectedItems.filter(i => i.show) : cursorElement}
          user={user}
          descriptionSuggestions={descriptionSuggestions}
          descriptionSuggestionLoading={descriptionSuggestionLoading}
          onSubmitMedia={onSubmitMedia}
          onActionMedia={onActionMedia}
          onUpdateMedia={onUpdateMedia}
          onRefetchMedia={onRefetchMedia}
        />
      </div>
      {!!submittedCount.length && (user?.isKeyworder) && (
        <UndoSnackbar
          count={submittedCount}
          onFinish={onUndoSnackbarFinish}
          onUndo={onUndoSnackbar}
        />
      )}
    </div>
  );
};
