import {
  getItemFromLocalStorage,
} from './StorageService';
import { http } from './ApiService';
import { AUTH_DATA } from '../constants/storage.constants';
import { ROLE_CURATOR, ROLE_REVIEWER } from '../constants/roles.constants';

export function getStats(time_unit: string, period_from: string, period_to: string, role?: string): Promise<any> {
  const { userId } = getItemFromLocalStorage(AUTH_DATA, true);

  return http.get(
    (role === ROLE_CURATOR || role === ROLE_REVIEWER) ? `/api/media/action-log/curated-summary?user_id=${userId}&time_unit=${time_unit}&period_from=${period_from}&period_to=${period_to}` :
      `/api/media/action-log/content-ready-summary?user_id=${userId}&time_unit=${time_unit}&period_from=${period_from}&period_to=${period_to}`,
  );
}
