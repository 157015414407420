import jwt_decode from 'jwt-decode';
import { JWTToken } from '../../types';
import { getItemFromLocalStorage } from '../../services/StorageService';
import {
  ROLE_ADMIN,
  ROLE_BUSINESS, ROLE_CHALLENGE_ADMIN,
  ROLE_CREATOR,
  ROLE_CURATOR, ROLE_RANKER,
  ROLE_REVIEWER,
  ROLE_SUBMITTER,
  SELECTED_ROLE,
} from '../../constants/roles.constants';

export class UserDTO {
  id?: number;

  email?: string;

  username?: string;

  role?: string[];

  avatar?: string;

  name?: string;

  displayName?: string;

  selectedRole?: string;

  isCurator?: boolean;

  isKeyworder?: boolean;

  isReviewer?: boolean;

  isRanker?: boolean;

  isAdmin?: boolean;

  isChallengeAdmin?: boolean;

  constructor(userData: any, token: string) {
    const decodedToken: JWTToken = jwt_decode(token);

    this.id = userData?.id;
    this.email = userData?.email;
    this.username = userData?.username;
    this.name = userData?.name;
    this.displayName = userData?.display_name;
    this.avatar = userData?.avatar;

    this.role = decodedToken?.scopes?.filter((role) => role !== ROLE_BUSINESS && role !== ROLE_CREATOR);
    this.selectedRole = getItemFromLocalStorage(SELECTED_ROLE) || this.role[0];
    this.isCurator = this.selectedRole?.includes(ROLE_CURATOR);
    this.isKeyworder = this.selectedRole?.includes(ROLE_SUBMITTER);
    this.isReviewer = this.selectedRole?.includes(ROLE_REVIEWER);
    this.isRanker = this.selectedRole?.includes(ROLE_RANKER);
    this.isAdmin = this.selectedRole?.includes(ROLE_ADMIN);
    this.isChallengeAdmin = this.selectedRole?.includes(ROLE_CHALLENGE_ADMIN);
  }
}
