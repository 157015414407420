import React, { useState } from 'react';
import { useKey } from 'rooks';
import { Typography, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import './login.main.scss';
import { LoadingProgress } from '../../components/LoadingProgress';
import { LoginHeader } from '../../components/LoginHeader';
import { StyledButton } from '../../components/StyledButton';
import { useLogin } from '../../queries';

export const Login = (): JSX.Element => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const {
    isLoading,
    error,
    refetch,
  } = useLogin(credentials, {
    enabled: false,
  });

  const handleCredentialsChange = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
    setCredentials({ ...credentials, [property]: event.target.value });
  };

  const loginHandler = async () => {
    refetch();
  };

  useKey(13, loginHandler);

  return (
    <div className="Login">
      <LoginHeader />

      <div className="Main-container">
        <Typography variant="h3">Sign In</Typography>
        {!!isLoading && (
          <LoadingProgress />
        )}
        {!!error && (
          <div className="login-error-message">
            <ErrorIcon />
            <p>These credentials do not match our records.</p>
          </div>
        )}
        <form
          action=""
          autoComplete="off"
        >
          <div className="login-form-elements">
            <TextField
              type="email"
              size="small"
              variant="outlined"
              label="Email"
              name="email"
              required
              value={credentials.username}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCredentialsChange(event, 'username')}
            />
            <TextField
              type="password"
              size="small"
              variant="outlined"
              label="Password"
              name="password"
              required
              value={credentials.password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCredentialsChange(event, 'password')}
            />
          </div>
          <StyledButton
            className="home-element-button"
            variant="contained"
            color="primary"
            onClick={loginHandler}
            disabled={!credentials.username || !credentials.password}
          >
            Sign In
          </StyledButton>
        </form>
      </div>
    </div>
  );
};
