import React, {
  createContext, useState, useEffect, useContext,
} from 'react';
import { useUserDetails } from '../queries';
import { UserDTO } from '../models/User/User.dto';
import {
  ROLE_ADMIN, ROLE_CHALLENGE_ADMIN,
  ROLE_CURATOR,
  ROLE_RANKER,
  ROLE_REVIEWER,
  ROLE_SUBMITTER,
  SELECTED_ROLE,
} from '../constants/roles.constants';
import { setItemToLocalStorage } from '../services/StorageService';

const initialUserContext: {
  user?: UserDTO;
  isLoading: boolean;
  changeRole: (role: string) => void;
} = {
  user: {},
  isLoading: false,
  changeRole: () => {
  },
};

export const UserContext = createContext(initialUserContext);

export const UserProvider = ({ children }: { children: JSX.Element }) => {
  const { data: fetchedData, isLoading } = useUserDetails();
  const [user, setUser] = useState<UserDTO>();

  useEffect(() => {
    setUser(fetchedData);
  }, [fetchedData]);

  useEffect(() => {
    user?.selectedRole && setItemToLocalStorage(SELECTED_ROLE, user?.selectedRole);
  }, [user]);

  const changeRole = (newRole: string) => {
    setUser((prevUser: any) => {
      if (prevUser) {
        return {
          ...prevUser,
          selectedRole: newRole,
          isCurator: newRole?.includes(ROLE_CURATOR),
          isKeyworder: newRole?.includes(ROLE_SUBMITTER),
          isReviewer: newRole?.includes(ROLE_REVIEWER),
          isRanker: newRole?.includes(ROLE_RANKER),
          isAdmin: newRole?.includes(ROLE_ADMIN),
          isChallengeAdmin: newRole?.includes(ROLE_CHALLENGE_ADMIN),
        };
      }

      return prevUser;
    });
  };

  return (
    <UserContext.Provider value={{ user, isLoading, changeRole }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
