import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {
  Popup,
  IconButton,
  Button,
  InputText,
  Textarea,
  Text,
  IconClose,
  IconPlus, IconVerified, IconCheck, IconOpenInNew,
  Checkbox,
  Switcher,
  SwitcherItem,
} from '@wirestock.io/design-components';
import { ChallengeContentSection } from './ChallengeContentSection';
import { ChallengeSubmissions } from '../ChallengeSubmissions';
import { ChallengeUploadButton } from '../ChallengeUploadButton';
import * as Styled from './ChallengeContent.styles';
import { ChallengeDateTimePicker } from '../../../components/ChallengeDateTimePicker';
import { ChallengeType } from '../../../constants/challenge.constants';
import { ChallengeTypeSelector } from '../../../components/ChallengeTypeSelector/ChallengeTypeSelector';

interface ChallengeContentProps {
  selectedChallenge: any;
  handleUpdateChallenge: (updatedData: any) => void;
  handleCreateChallenge: (data: any) => void;
  handleDeleteChallenge: (challengeId: string) => void;
  loadingUpdateChallenge?: boolean;
}

export const ChallengeContent = ({
  selectedChallenge,
  handleUpdateChallenge,
  handleCreateChallenge,
  handleDeleteChallenge,
  loadingUpdateChallenge,
}: ChallengeContentProps) => {
  const initialData = selectedChallenge?.content;
  const [coverPhoto, setCoverPhoto] = useState(initialData?.coverPhotoUrl ?? '');
  const [album, setAlbum] = useState<any>(initialData?.album ?? []);
  const [isPrivate, setIsPrivate] = useState<any>(initialData?.isPrivate ?? true);
  const [sections, setSections] = useState<any>([]);
  const [challengeTitle, setChallengeTitle] = useState<string>('');
  const [challengeType, setChallengeType] = useState<string>(ChallengeType.photo.value);
  const [description, setDescription] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>();
  const [deadline, setDeadline] = useState<Date>();
  const [slug, setSlug] = useState<string>('');
  const [hasOpenAccess, setHasOpenAccess] = useState<boolean>(false);
  const [prizeFund, setPrizeFund] = useState<string>('');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isSubmissionsOpen, setIsSubmissionsOpen] = useState<boolean>(false);

  useEffect(() => {
    const transformedData = initialData?.sections?.map(({ title, content, images }: any) => ({
      title,
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(content).contentBlocks)),
      images,
    }));
    setIsPrivate(selectedChallenge?.isPrivate);
    setChallengeType(selectedChallenge?.type ?? ChallengeType.photo.value);
    setAlbum(initialData?.album ?? []);
    setCoverPhoto(initialData?.coverPhotoUrl ?? '');
    setSections(transformedData || []);
    setHasOpenAccess(selectedChallenge?.isOpenAccess ?? false);
    setChallengeTitle(selectedChallenge?.title ?? '');
    setDescription(selectedChallenge?.description ?? '');
    setStartDate(selectedChallenge?.startDate);
    setDeadline(selectedChallenge?.deadlineDate);
    setSlug(selectedChallenge?.slug ?? 'photography-challenge');
    setPrizeFund(selectedChallenge?.prizeFund ?? '');
  }, [selectedChallenge?.id]);

  const openSubmissionsPopup = () => {
    setIsSubmissionsOpen(true);
  };

  const closeSubmissionsPopup = () => {
    setIsSubmissionsOpen(false);
  };

  const updateSection = (index: number, newSection: any) => {
    setSections((prevSections: any) => {
      const updatedSections = [...prevSections];
      updatedSections[index] = newSection;
      return updatedSections;
    });
  };

  const addSection = () => {
    setSections([
      ...sections,
      {
        title: '',
        editorState: EditorState.createEmpty(),
        images: [],
      },
    ]);
  };

  const deleteSection = (index: number) => {
    setSections(sections.filter((_: any, i: number) => i !== index));
  };

  const constructChangedData = () => {
    return {
      isPrivate,
      type: challengeType,
      title: challengeTitle,
      description: description,
      slug: slug,
      startDate: startDate,
      deadlineDate: deadline,
      prizeFund: prizeFund,
      prizeDetails: {},
      content: {
        coverPhotoUrl: coverPhoto,
        album,
        sections: sections?.map(({ title, editorState, images }: any) => ({
          title,
          content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          images,
        })),
      },
      isOpenAccess: hasOpenAccess,
    };
  };

  const handleUpdateChallengeClick = () => {
    handleUpdateChallenge(constructChangedData());
  };

  const handleCreateChallengeClick = () => {
    handleCreateChallenge(constructChangedData());
  };

  const handleDeleteChallengeClick = () => {
    setIsDeletePopupOpen(false);
    handleDeleteChallenge(selectedChallenge?.id);
  };

  const handleChallengeTypeChange = (selectedType: string) => {
    setChallengeType(selectedType);
    if (slug === '' || !selectedChallenge?.id) {
      switch (selectedType) {
        case ChallengeType.photo.value:
          setSlug('photography-challenge');
          break;
        case ChallengeType.video.value:
          setSlug('video-challenge');
          break;
        case ChallengeType.aiPhoto.value:
          setSlug('creative-ai-photo-challenge');
          break;
        case ChallengeType.aiVideo.value:
          setSlug('creative-ai-video-challenge');
          break;
        default:
          break;
      }
    }
  };

  const handleChallengePrivacyChange = (value: any) => {
    setIsPrivate(!value);
  };

  const openChallengePage = (challengeSlug: string) => {
    window.open(`https://wirestock.io/challenges/${challengeSlug}`, '_blank');
  };
  return (
    <Styled.ChallengeContentWrapper>
      <Styled.ChallengeContentHeader>
        <Styled.ChallengeContentHeaderInfo>
          <Styled.ChallengeContentHeaderTitle>
            <Text
              tag="h3"
              text={selectedChallenge?.id ? selectedChallenge?.title : challengeTitle}
            />

            {selectedChallenge?.id && (
              <Styled.ChallengeContentHeaderIcon onClick={() => openChallengePage(selectedChallenge?.slug)}>
                <IconOpenInNew
                  width={20}
                  height={20}
                />
              </Styled.ChallengeContentHeaderIcon>
            )}
          </Styled.ChallengeContentHeaderTitle>
          <Text
            tag="span"
            text={!selectedChallenge?.isPrivate ? `${selectedChallenge?.submissionCount} submissions` : 'Private'}
          />
        </Styled.ChallengeContentHeaderInfo>
        <Styled.ChallengeContentHeaderActions>
          {selectedChallenge?.id ? (
            <>
              {selectedChallenge?.submissionCount > 0 && (
                <Button
                  slotLeft={(
                    <IconVerified
                      fill="white"
                      width={16}
                      height={16}
                    />
                  )}
                  type="tertiary"
                  label="See submissions"
                  onClick={openSubmissionsPopup}
                />
              )}
              <Button
                slotLeft={(
                  <IconCheck
                    fill="white"
                    width={16}
                    height={16}
                  />
                )}
                onClick={handleUpdateChallengeClick}
                type="primary"
                isLoading={loadingUpdateChallenge}
                label="Save challenge"
              />
            </>
          ) : (
            <Button
              slotLeft={(
                <IconCheck
                  fill="white"
                  width={16}
                  height={16}
                />
              )}
              onClick={handleCreateChallengeClick}
              type="primary"
              label="Create challenge"
            />
          )}
          <Button
            type="destructive"
            onClick={() => setIsDeletePopupOpen(true)}
            label="Delete"
          />
        </Styled.ChallengeContentHeaderActions>
        <Popup
          closeOnOutsideClick={false}
          popupSize="fullscreen"
          isOpen={isSubmissionsOpen}
          onClose={closeSubmissionsPopup}
          className="challenge-submissions-popup"
        >
          <ChallengeSubmissions
            challenge={selectedChallenge}
            isOpen={isSubmissionsOpen}
          />
        </Popup>
        <Popup
          isOpen={isDeletePopupOpen}
          onClose={() => setIsDeletePopupOpen(false)}
        >
          <>
            <Text
              tag="p1"
              text="Are you sure you want to delete this challenge?"
            />
            <Styled.ButtonsContainer>
              <Button
                label="Cancel"
                type="secondary"
                onClick={() => setIsDeletePopupOpen(false)}
              />
              <Button
                type="destructive"
                label="Delete"
                onClick={handleDeleteChallengeClick}
              />
            </Styled.ButtonsContainer>
          </>
        </Popup>
      </Styled.ChallengeContentHeader>
      <Styled.ChallengeContentBody>
        <Styled.ChallengeContentContainer>
          <Styled.ChallengeMainInfo>
            <Styled.ChallengeMainInfoForm>
              <Styled.ChallengePrivacySwitcher>
                <Switcher onChange={handleChallengePrivacyChange} value={isPrivate ? 0 : 1}>
                  <SwitcherItem value={0}>
                    Private
                  </SwitcherItem>
                  <SwitcherItem value={1}>
                    Public
                  </SwitcherItem>
                </Switcher>
              </Styled.ChallengePrivacySwitcher>
              <Styled.FormElement>
                <ChallengeTypeSelector challengeType={challengeType} handleChallengeTypeChange={handleChallengeTypeChange} />
              </Styled.FormElement>
              <Styled.FormElementWithText>
                <InputText
                  value={challengeTitle}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChallengeTitle(e?.target?.value)}
                  label="Challenge title"
                />
              </Styled.FormElementWithText>
              <Styled.FormElement>
                <Textarea
                  value={description}
                  label="Short description"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e?.target?.value)}
                  rows={3}
                />
              </Styled.FormElement>
              <Styled.FormElementWithText>
                <InputText
                  value={slug}
                  label="Slug"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSlug(e?.target?.value)}
                />
                <Text tag="span" text="Change the slug name to your preffered one." color="gray" reset />
              </Styled.FormElementWithText>
              <Styled.FormElement>
                <InputText
                  value={prizeFund}
                  label="Prizes"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrizeFund(e?.target?.value)}
                />
              </Styled.FormElement>
              <Styled.FormElement>
                <ChallengeDateTimePicker
                  label="Start date"
                  date={Moment(startDate).format('YYYY-MM-DD HH:mm:ss')}
                  onChange={(date: any) => setStartDate(date)}
                />
              </Styled.FormElement>
              <Styled.FormElement>
                <ChallengeDateTimePicker
                  label="End date"
                  date={Moment(deadline).format('YYYY-MM-DD HH:mm:ss')}
                  onChange={(date: any) => setDeadline(date)}
                />
              </Styled.FormElement>
              {selectedChallenge?.id && (
                <Styled.FormElement>
                  <InputText
                    type="url"
                    disabled={!selectedChallenge?.id}
                    hasError={!coverPhoto && selectedChallenge?.id}
                    value={coverPhoto}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCoverPhoto(e.target.value)}
                    label="Cover photo url"
                  />
                  <ChallengeUploadButton
                    disabled={!selectedChallenge?.id}
                    challengeId={selectedChallenge?.id}
                    onSuccess={(uploadedFileUrl) => setCoverPhoto(uploadedFileUrl)}
                  />
                </Styled.FormElement>
              )}
              <Styled.FormElement>
                <Checkbox
                  checked={hasOpenAccess}
                  onClick={() => setHasOpenAccess(!hasOpenAccess)}
                  label="Open access"
                />
              </Styled.FormElement>
            </Styled.ChallengeMainInfoForm>
            <Styled.ChallengeMainInfoCoverImage>
              <img
                src={coverPhoto}
                alt="Challenge cover background"
              />
            </Styled.ChallengeMainInfoCoverImage>
          </Styled.ChallengeMainInfo>
          {selectedChallenge?.id && (
            <Styled.ChallengePreviewImages>
              <Text
                tag="p3"
                weight="500"
                text="Challenge preview images"
              />
              <Styled.ChallengePreviewImageItems>
                {album.map((imageUrl: string, index: number) => (
                  <Styled.ChallengePreviewImageItem
                    key={index}
                    className="album-image"
                  >
                    <InputText
                      type="text"
                      value={imageUrl}
                      onChange={(e: any) => {
                        const newAlbum: any = [...album];
                        newAlbum[index] = e.target.value;
                        setAlbum(newAlbum);
                      }}
                    />
                    <ChallengeUploadButton
                      disabled={!selectedChallenge?.id}
                      challengeId={selectedChallenge?.id}
                      onSuccess={(uploadedFileUrl) => {
                        const newAlbum: any = [...album];
                        newAlbum[index] = uploadedFileUrl;
                        setAlbum(newAlbum);
                      }}
                    />
                    <img
                      src={imageUrl}
                      alt={`Preview ${index + 1}`}
                    />
                    <IconButton
                      onClick={() => setAlbum(album.filter((_: any, i: number) => i !== index))}
                      icon={(
                        <IconClose
                          width={15}
                          height={15}
                        />
                      )}
                      variant="transparent"
                    />
                  </Styled.ChallengePreviewImageItem>
                ))}
              </Styled.ChallengePreviewImageItems>
              <Button
                disabled={!selectedChallenge?.id}
                onClick={() => {
                  setAlbum([...album, '']);
                }}
                label="Add preview image"
              />
            </Styled.ChallengePreviewImages>
          )}
          {sections.map((section: any, index: number) => (
            <ChallengeContentSection
              key={index}
              index={index}
              section={section}
              challengeId={selectedChallenge?.id}
              updateSection={updateSection}
              deleteSection={deleteSection}
            />
          ))}
          {selectedChallenge?.id && (
            <Styled.AddSectionButton>
              <Button
                type="secondary"
                slotLeft={(
                  <IconPlus
                    width={15}
                    height={15}
                  />
                )}
                disabled={!selectedChallenge?.id}
                onClick={addSection}
                label="Add Section"
              />
            </Styled.AddSectionButton>
          )}
        </Styled.ChallengeContentContainer>
      </Styled.ChallengeContentBody>
    </Styled.ChallengeContentWrapper>
  );
};
