import React from 'react';

import { useHistory } from 'react-router-dom';
import { StyledButton } from '../../../../components/StyledButton';
import homeIllustration from '../../../../assets/images/home-illustration.png';

export const ChallengeAdminView = (): JSX.Element => {
  const history = useHistory();

  const redirectToChallengeManager = () => {
    history.push('/challenge-manager');
  };

  return (
    <div className="Home-element">
      <h1>Welcome back!</h1>
      <img src={homeIllustration} alt="" />

      <StyledButton
        className="home-element-button"
        variant="contained"
        color="primary"
        onClick={redirectToChallengeManager}
      >
        Manage Challenges
      </StyledButton>
    </div>
  );
};
